import { TrackJS } from 'trackjs';
import { useDebugStore } from '~/stores/debugStore';
import { useSiteContentStore } from '~~/stores/siteContent';

export function useSystemText() {
  const siteContent = useSiteContentStore();
  const debugStore = useDebugStore();

  const systemText = (key: string, variable: string | null = null): string => {
    // if (process.env.NODE_ENV === 'development' ) return key; // hide for now to make layout better in development mode
    if (debugStore.debugText) return key;

    let value: string = '';

    if (key) {
      if (systemTextExists(key)) {
        value = siteContent.systemTextLowerCasedKeys.get(key.toLowerCase()) ?? '';
      } else {
        TrackJS.track(`systemText key not found: ${key}`);
        return '';
      }
    }

    return variable ? value.replace(/%s/g, variable) : value.replace(/%s/g, '');
  };

  const systemTextExists = (key: string): boolean => {
    if (key) {
      return siteContent.systemTextLowerCasedKeys.has(key.toLowerCase());
    }
    return false;
  };

  return { systemText, systemTextExists };
}
