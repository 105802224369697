import { TrackJS } from 'trackjs';

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig();
  TrackJS.install({
    version: String(config.public.RELEASE_VERSION),
    token: '9c3181d7c83b46dea8f530398b7fb9e6',
    application: config.public.trackjsApplication,
  });
});
