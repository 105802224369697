import type { branchObjectRepository } from '~/api/apollo/branchObject.repository';
import type { carObjectRepository } from '~/api/apollo/carObject.repository';
import type { productRepository } from '~/api/apollo/product.repository';
import type { carModelRepository } from '~/api/apollo/carModel.repotitory';
import type { pageContentRepository } from '~/api/apollo/pageContent.repository';
import type { favoritesRepository } from '~/api/apollo/favorites.repository';
import type { siteContentRepository } from '~/api/apollo/siteContent.repository';
import type { siteSearchRepository } from '~/api/apollo/siteSearch.repository';
import type { formRepository } from '~/api/apollo/form.repository';
import type { subscriptionRepository } from '~/api/apollo/subscription.repository';
import type { dynamicListRepository } from '~/api/apollo/dynamicList.repository';
import type { registrationRepository } from '~/api/apollo/registration.repository';
import type { cartRepository } from '~/api/apollo/cart.repository';
import type { checkoutRepository } from '~/api/apollo/checkout.repository';
import type { tireContentRepository } from '~/api/apollo/tireContent.repository';

declare module '#app' {
  interface NuxtApp {
    $branchObjectRepository: ReturnType<typeof branchObjectRepository>;
    $carObjectRepository: ReturnType<typeof carObjectRepository>;
    $carModelRepository: ReturnType<typeof carModelRepository>;
    $productRepository: ReturnType<typeof productRepository>;
    $cartRepository: ReturnType<typeof cartRepository>;
    $checkoutRepository: ReturnType<typeof checkoutRepository>;
    $pageContentRepository: ReturnType<typeof pageContentRepository>;
    $siteSearchRepository: ReturnType<typeof siteSearchRepository>;
    $favoritesRepository: ReturnType<typeof favoritesRepository>;
    $siteContentRepository: ReturnType<typeof siteContentRepository>;
    $formRepository: ReturnType<typeof formRepository>;
    $subscriptionRepository: ReturnType<typeof subscriptionRepository>;
    $dynamicListRepository: ReturnType<typeof dynamicListRepository>;
    $registrationRepository: ReturnType<typeof registrationRepository>;
    $tireContentRepository: ReturnType<typeof tireContentRepository>;
  }
}

export default defineNuxtPlugin(async () => {
  return {
    provide: {
      branchObjectRepository: (await import('~/api/apollo/branchObject.repository')).branchObjectRepository(),
      carObjectRepository: (await import('~/api/apollo/carObject.repository')).carObjectRepository(),
      carModelRepository: (await import('~/api/apollo/carModel.repotitory')).carModelRepository(),
      productRepository: (await import('~/api/apollo/product.repository')).productRepository(),
      pageContentRepository: (await import('~/api/apollo/pageContent.repository')).pageContentRepository(),
      favoritesRepository: (await import('~/api/apollo/favorites.repository')).favoritesRepository(),
      siteContentRepository: (await import('~/api/apollo/siteContent.repository')).siteContentRepository(),
      siteSearchRepository: (await import('~/api/apollo/siteSearch.repository')).siteSearchRepository(),
      formRepository: (await import('~/api/apollo/form.repository')).formRepository(),
      subscriptionRepository: (await import('~/api/apollo/subscription.repository')).subscriptionRepository(),
      dynamicListRepository: (await import('~/api/apollo/dynamicList.repository')).dynamicListRepository(),
      registrationRepository: (await import('~/api/apollo/registration.repository')).registrationRepository(),
      cartRepository: (await import('~/api/apollo/cart.repository')).cartRepository(),
      checkoutRepository: (await import('~/api/apollo/checkout.repository')).checkoutRepository(),
      tireContentRepository: (await import('~/api/apollo/tireContent.repository')).tireContentRepository(),
      workshopBookingRepository: (await import('~/api/apollo/workshopBooking.repository')).workshopBookingRepository(),
    },
  };
});
