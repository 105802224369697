import type { Emitter } from 'mitt';
import mitt from 'mitt';

export default defineNuxtPlugin(() => {
  const emitter = mitt();

  return {
    provide: {
      bus: {
        $on: emitter.on,
        $emit: emitter.emit,
        $off: emitter.off,
      },
    },
  };
});

type Events = {
  [key: string]: string;
};

interface Bus {
  $on: Emitter<Events>['on'];
  $emit: Emitter<Events>['emit'];
  $off: Emitter<Events>['off'];
}

declare module '#app' {
  interface NuxtApp {
    $bus: Bus;
  }
}
