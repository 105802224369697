export const getDefinedComponent = (name: string | undefined) => {
  switch (name) {
    case 'FINANCE_FILTER':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FinanceFilter.vue`));
    case 'RANGE':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterRange.vue`));
    case 'HISTOGRAM_RANGE':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterRange.vue`));
    case 'TERMS':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterOptions.vue`));
    case 'TERMS_BOOLEAN':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterSingleOptions.vue`));
    case 'TERMS_COLOR':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterOptions.vue`));
    case 'UspMixInBlock':
      return defineAsyncComponent(() => import(`@/components/listable/USPMixinCard.vue`));
    case 'Car':
    case 'CarObjectLeadinCard':
      return defineAsyncComponent(() => import(`@/components/listable/CarObjectLeadinCard.vue`));
    case 'CategoryLeadinCard':
      return defineAsyncComponent(() => import(`@/components/listable/CategoryLeadinCard.vue`));
    case 'GenericLeadinCard':
      return defineAsyncComponent(() => import(`@/components/listable/GenericLeadinCard.vue`));
    case 'OfferLeadinBlockCard':
      return defineAsyncComponent(() => import(`@/components/blocks/OfferLeadinBlockCard.vue`));
    case 'ServiceLeadinCard':
      return defineAsyncComponent(() => import(`@/components/listable/ServiceLeadinCard.vue`));
    case 'USPCard':
      return defineAsyncComponent(() => import(`@/components/listable/USPCard.vue`));
    case 'WorkshopBookBarBlockCard':
      return defineAsyncComponent(() => import(`@/components/blocks/WorkshopBookBarBlockCard.vue`));
    case 'BrandLeadinCard':
      return defineAsyncComponent(() => import(`@/components/listable/BrandLeadinCard.vue`));
    case 'Card':
      return defineAsyncComponent(() => import(`@/components/generic/Card.vue`));
    case 'RANGE_SINGLE':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterRangeSingle.vue`));
    case 'TERMS_DROPDOWN':
      return defineAsyncComponent(() => import(`@/components/blocks/partials/searchFilter/FilterDropdown.vue`));
    case 'section':
      return 'section';
    // return defineAsyncComponent(() => import('@/components/blocks/SectionBlock.vue'));
    case 'LinkCollectionBlock':
      return defineAsyncComponent(() => import('@/components/blocks/LinkCollectionBlock.vue'));
    case 'CategoryLinksBlock':
      return defineAsyncComponent(() => import('@/components/blocks/CategoryLinksBlock.vue'));
    case 'FormElementCallToAction':
      return defineAsyncComponent(() => import('@/components/form/FormElementCallToAction.vue'));
    case 'Button':
      return defineAsyncComponent(() => import('@/components/generic/Button.vue'));
    case 'Link':
      return defineAsyncComponent(() => import('@/components/generic/Link.vue'));
    case 'bilia':
      return defineAsyncComponent(() => import('@/sites/bilia/SiteTheme.vue'));
    case 'outlet':
      return defineAsyncComponent(() => import('@/sites/outlet/SiteTheme.vue'));
    case 'ebds':
      return defineAsyncComponent(() => import('@/sites/ebds/SiteTheme.vue'));
    case 'CarModelTabWrapperBlock':
      return defineAsyncComponent(() => import('@/components/blocks/CarModelTabWrapperBlock.vue'));
    case 'GenericContentBlockCard':
      return defineAsyncComponent(() => import('@/components/blocks/GenericContentBlockCard.vue'));
    case 'mc':
      return defineAsyncComponent(() => import('@/sites/mc/SiteTheme.vue'));
    case 'ora':
      return defineAsyncComponent(() => import('@/sites/ora/SiteTheme.vue'));
    case 'abd':
      return defineAsyncComponent(() => import('@/sites/abd/SiteTheme.vue'));
    case 'CarModelPageBlock':
      return defineAsyncComponent(() => import(`@/components/listable/CarModelCard.vue`));
    case 'SiteSearchPageType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/content/ContentResult.vue`));
    case 'SiteSearchBranchType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/branch/BranchResult.vue`));
    case 'SiteSearchProductType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/product/ProductResult.vue`));
    case 'SiteSearchTireType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/product/ProductResult.vue`));
    case 'SiteSearchCarType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/car/CarResult.vue`));
    case 'SiteSearchCarModelType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/car/CarModelResult.vue`));
    case 'SiteSearchWorkshopServiceType':
      return defineAsyncComponent(() => import(`@/components/siteSearch/workshop/WorkshopServiceResult.vue`));
    default:
      console.log('MISSING DEFINED', name);
      return name;
  }
};
