import { defineStore } from 'pinia';

interface DebugStoreState {
  _debugText: boolean;
}

const initialState: () => DebugStoreState = () => {
  return {
    _debugText: false,
  };
};

export const useDebugStore = defineStore('debugStore', {
  state: initialState,
  getters: {
    debugText: (state: DebugStoreState): boolean => state._debugText,
  },
  actions: {
    setDebugText(val: boolean): void {
      this._debugText = val;
    },
  },
});
