// https://www.damirscorner.com/blog/posts/20200904-ErrorHandlingInNuxtjs.html

import { TrackJS } from 'trackjs';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    if (route.path.indexOf('_nuxt') === -1) {
      TrackJS.track(error as object);
    }
  };
});
