import { useRoute } from 'vue-router';
export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  if (config.public.datadogEnabled) {
    const datadogRum = (await import('@datadog/browser-rum')).datadogRum;
    datadogRum.init({
      applicationId: config.public.datadogApplicationId,
      clientToken: config.public.datadogClientToken,
      site: config.public.datadogSite,
      service: config.public.datadogService,
      env: process.env.NODE_ENV,
      version: config.public.releaseVersion as string,
      sessionSampleRate: Number(config.public?.datadogSampleRate ?? 0),
      sessionReplaySampleRate: Number(config.public?.datadogReplaySampleRate ?? 0),
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: config?.public.datadogTrackUserInteractionsAll ?? false,
      enablePrivacyForActionName: config.public.datadogMaskAll,
    });
    if (Number(config.public?.datadogReplaySampleRate ?? 0) > 0) {
      datadogRum.startSessionReplayRecording(); // Used for session replays
    }
  }
});
